//@ts-ignore-next-line
import SockJS from 'sockjs-client/dist/sockjs'
import Stomp from 'webstomp-client'

import { IDECONSTANT, SERVER_ERROR } from '@/utils/ide'

// import gaService from '@/services/ga.service'
import aiService from '@/services/ide/ai.service'
import editorService from '@/services/ide/editor.service'
import executeService, { type IExecuteRequest } from '@/services/ide/execute.service'
import functionalFeaturesService from '@/services/ide/plugin/functionalFeatures.service'
import projectsService from '@/services/ide/projects.service'

import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'

/**
 * On ws connection failed
 */
const onWsConnectionFailed = () => {
  useIdeStore().setisCodeExecuting(false)
  enableOutputEditorControls()
  editorService.insertEditorSession(
    IDECONSTANT.OUTPUT_EDITOR,
    'Connection to server lost.\n It is possible your browser or internet connection may not support the ' +
      'Interactive mode.\n Please try again, or try Non-Interactive mode. Alternatively contact JDoodle ' +
      'support at hello@jdoodle.com.'
  )
}
/**
 * On ws end
 */
// const postInteractiveExecute = (startTime: number = 0) => {
const postInteractiveExecute = () => {
  useIdeStore().socketClient.disconnect()
  useIdeStore().setisCodeExecuting(false)
  enableOutputEditorControls()
  // gaService.calculateAndSendExecuteEndTime(startTime, useIdeStore().isLanguage, 'execute-i')
}
/**
 * On ws connection
 * @param requestData - The request data
 */
const onWsConnection = (requestData: IExecuteRequest | null) => {
  useIdeStore().socketClient.subscribe('/user/queue/execute-i', (message: any) => {
    const msgId = message.headers['message-id']
    const msgSeq = parseInt(msgId.substring(msgId.lastIndexOf('-') + 1))

    const statusCode = parseInt(message.headers.statusCode)

    if (statusCode === 201) {
      useIdeStore().setwsNextId(msgSeq + 1)
      return
    }

    // let t0 = 0
    // try {
    //   t0 = performance.now()
    //   while (performance.now() - t0 < 2500 && useIdeStore().isWsNextId !== msgSeq) {
    //     // eslint-disable-next-line no-empty
    //   }
    // } catch (e) {
    //   // eslint-disable-next-line no-console
    //   console.error(e)
    // }

    if (statusCode === 204) {
      useIdeStore().setExecutionqTime(message.body)
      postInteractiveExecute()
      const outputScript = editorService.getEditorSession(IDECONSTANT.OUTPUT_EDITOR)
      const outputHasError = aiService.checkOutputHasError(outputScript)

      if (outputHasError) {
        // If error, store in ideStore
        useIdeStore().setHasErrorAfterExecute(true)
      } else {
        //If no error - store in ideStore
        useIdeStore().setHasErrorAfterExecute(false)
      }
    } else if (statusCode === 500) {
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
      useIdeStore().setisCodeExecuting(false)
      enableOutputEditorControls()
    } else if (statusCode === 206) {
      useIdeStore().setOutputFiles(JSON.parse(message.body))
    } else if (statusCode === 403) {
      useAuthStore().clearRobotCheck()
      editorService.insertEditorSession(IDECONSTANT.OUTPUT_EDITOR, message.body)
      functionalFeaturesService.onCustomPluginExecuteComplete(message.body as string)
    } else if (statusCode === 429) {
      const limitReached = executeService.handleExecutionLimit(message.body as string)
      if (limitReached) {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')
        useIdeStore().resetExecutionTime()
      }
    } else if (statusCode !== 410) {
      editorService.insertEditorSession(IDECONSTANT.OUTPUT_EDITOR, message.body)
      functionalFeaturesService.onCustomPluginExecuteComplete(message.body as string)
    }
    useIdeStore().setwsNextId(msgSeq + 1)
  })

  useIdeStore().socketClient.send('/app/execute-i', JSON.stringify(requestData), {
    message_type: 'execute'
  })
  editorService.forcusEditor(IDECONSTANT.OUTPUT_EDITOR)
}
/**
 * Execute interactive with ws
 * @param requestData - The request data
 */
const executeInteractive = (requestData: IExecuteRequest | null) => {
  disableOutputEditorControls()

  if (useIdeStore().socketClient) {
    useIdeStore().socketClient.disconnect()
    useIdeStore().socketClient = null
  }
  useIdeStore().socketClient = Stomp.over(new SockJS('/engine/stomp'), {
    heartbeat: false,
    debug: false
  })

  editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')

  useIdeStore().wsNextId = 0

  // const startTime = gaService.getCurrentTime()

  useIdeStore().socketClient?.connect(
    {},
    () => {
      useIdeStore().setSocketConnected(true)
      onWsConnection(requestData)
    },
    onWsConnectionFailed
  )
  projectsService.autoSave()
}
/**
 * stop when user click stop button
 */
const stopExecuteInteractive = () => {
  useIdeStore().socketClient.disconnect(() => {
    useIdeStore().setSocketConnected(false)
    useIdeStore().setisCodeExecuting(false)
    enableOutputEditorControls()
  })
}
/**
 * On keyup output edition
 * called when editer setup at editor service onKeyupOutputEditor
 * @param event - The event
 */
const onKeyupOutputEditor = (event: KeyboardEvent) => {
  if (
    useIdeStore().interactiveMode &&
    useIdeStore().isCodeExecuting &&
    useIdeStore().socketClient
  ) {
    let key = event.key
    if (event.key === 'Enter') {
      key = '\n'
    }

    if (key === 'Backspace' || key === 'Delete') {
      key = '\b'
    }

    if (useIdeStore().socketClient && useIdeStore().socketClient.connected)
      useIdeStore().socketClient.send('/app/execute-i', key, { message_type: 'input' })
  }
}

// Store the function references so we can remove them later
let mousedownHandler: (e: Event) => void
let mouseupHandler: (e: Event) => void
let clickHandler: (e: Event) => void
let dblclickHandler: (e: Event) => void
let changeCursorHandler: () => Promise<void>
let changeSelectionHandler: () => Promise<void>

/**
 * This function prevents user from clicking, selecting, pasting, or moving cursors in the output editor
 */
const disableOutputEditorControls = () => {
  const editor = useIdeStore().outputEditor

  mousedownHandler = function (e: Event) {
    e.preventDefault()
  }

  mouseupHandler = function (e: Event) {
    e.preventDefault()
  }

  clickHandler = function (e: Event) {
    e.preventDefault()
  }

  dblclickHandler = function (e: Event) {
    e.preventDefault()
  }

  editor.on('mousedown', mousedownHandler)
  editor.on('mouseup', mouseupHandler)
  editor.on('click', clickHandler)
  editor.on('dblclick', dblclickHandler)

  // Override Ctrl + Backspace command
  editor.commands.addCommand({
    name: 'removewordleft',
    bindKey: { win: 'Ctrl-Backspace', mac: 'Command-Backspace' },
    exec: function (editor: ace['Editor']) {
      editor.remove('left')
    }
  })

  // Disable paste functionality by overriding the paste command
  editor.commands.addCommand({
    name: 'paste',
    description: 'Paste',
    bindKey: { win: 'Ctrl-V', mac: 'Command-V' },
    exec: function () {}
  })

  // Prevent cursor movement and reset undo manager
  changeCursorHandler = async function () {
    editor.session.getUndoManager().reset()
    await new Promise((resolve) => setTimeout(resolve, 1))
    editor.selection.moveCursorFileEnd()
  }

  editor.getSession().selection.on('changeCursor', changeCursorHandler)

  // Prevent selection and reset undo manager
  changeSelectionHandler = async function () {
    editor.session.getUndoManager().reset()
    await new Promise((resolve) => setTimeout(resolve, 1))
    editor.getSession().selection.clearSelection()
  }

  editor.getSession().selection.on('changeSelection', changeSelectionHandler)
}

/**
 * This function enables user to click, select, paste, or move cursors in the output editor
 */
const enableOutputEditorControls = () => {
  const editor = useIdeStore().outputEditor

  // Remove event listeners using stored function references
  editor.off('mousedown', mousedownHandler)
  editor.off('mouseup', mouseupHandler)
  editor.off('click', clickHandler)
  editor.off('dblclick', dblclickHandler)

  // Restore the default Ctrl + Backspace behavior
  editor.commands.addCommand({
    name: 'removewordleft',
    bindKey: { win: 'Ctrl-Backspace', mac: 'Command-Backspace' },
    exec: function (editor: ace['Editor']) {
      editor.removeWordLeft()
    }
  })

  // Restore original paste command
  editor.commands.addCommand({
    name: 'paste',
    description: 'Paste',
    exec: function (editor: ace['Editor'], args: any) {
      editor.$handlePaste(args)
    },
    scrollIntoView: 'cursor'
  })

  // Remove cursor and selection change handlers
  editor.getSession().selection.off('changeCursor', changeCursorHandler)
  editor.getSession().selection.off('changeSelection', changeSelectionHandler)
}

export default { executeInteractive, stopExecuteInteractive, onKeyupOutputEditor }
