export interface IApiPlan {
  id: number
  name: string
  value: string
  credits: string
}

export interface IBundledPlan {
  name: string
  value: string
  type?: string
}

export const apiPlansData: IApiPlan[] = [
  { id: 0, name: 'Free', value: 'Free', credits: '20' },
  { id: 1, name: '$10', value: '$10', credits: '1k' },
  { id: 2, name: '$20', value: '$20', credits: '2k' },
  { id: 3, name: '$50', value: '$50', credits: '5k' },
  { id: 4, name: '$100', value: '$100', credits: '10k' },
  { id: 5, name: '$200', value: '$200', credits: '20k' },
  { id: 6, name: '$300', value: '$300', credits: '30k' },
  { id: 7, name: '$400', value: '$400', credits: '40k' },
  { id: 8, name: '$500', value: '$500', credits: '50k' },
  { id: 9, name: 'Unlimited', value: '$990', credits: 'Unlimited' }
]

export const yearlyApiPlansData: IApiPlan[] = [
  { id: 0, name: 'Free', value: 'Free', credits: '20' },
  { id: 1, name: '$96', value: '$10_yearly', credits: '1k' },
  { id: 2, name: '$192', value: '$20_yearly', credits: '2k' },
  { id: 3, name: '$480', value: '$50_yearly', credits: '5k' },
  { id: 4, name: '$960', value: '$100_yearly', credits: '10k' },
  { id: 5, name: '$1920', value: '$200_yearly', credits: '20k' },
  { id: 6, name: '$2880', value: '$300_yearly', credits: '30k' },
  { id: 7, name: '$3840', value: '$400_yearly', credits: '40k' },
  { id: 8, name: '$4800', value: '$500_yearly', credits: '50k' },
  { id: 9, name: 'Unlimited', value: '$990_yearly', credits: 'Unlimited' }
]

export const pluginPlansData: IBundledPlan[] = [
  { name: 'pFree', value: '$0', type: 'Free' },
  { name: 'Student', value: '$4.99', type: 'Student' },
  { name: 'Student Yearly', value: '$48.00', type: 'Student Yearly' },
  { name: 'Pro', value: '$9.99', type: 'Pro' },
  { name: 'Pro Yearly', value: '$96.00', type: 'Pro Yearly' },
  { name: 'Teacher', value: '$24.99', type: 'Teacher' },
  { name: 'Teacher Yearly', value: '$240.00', type: 'Teacher Yearly' }
]

export const UNLIMITED_LIMIT = -999

export enum EXECUTION_LIMIT {
  PER_DAY10 = 'Per_Day10',
  PER_DAY100 = 'Per_Day100',
  PER_MONTH1000 = 'Per_Month1000'
}
